export const langData = [{
  Nation: 'TH',
  index: {
    rtext: 'สมาชิกใหม่',
    rbtn: 'ลงทะเบียน ',
    stext: 'เป็นสมาชิกอยู่แล้ว',
    sbtn: 'เข้าสู่ระบบ',
    ctext: 'นานาชาติ',
  },
  user: {
    text: 'สอบถามข้อมูลสมาชิก',
    ptitle: 'หมายเลขโทรศัพท์',
    ptext: 'Phone No.',
    or: 'หรือ',
    etitle: 'ที่อยู่อีเมล',
    etext: 'Email',
    ctext: 'นานาชาติ',
    sbtn: 'เข้าสู่ระบบ',
    rdtext: 'วันที่ลงทะเบียน'
  },
  registration: {
    ctext: 'นานาชาติ',
    PTip: 'กรุณากรอกหมายเลขโทรศัพท์มือถือที่ไม่มีรหัสประเทศ',
    tiptext: 'ขอบคุณที่เลือก Simplus! เข้าร่วมเป็นสมาชิก Simplus , รับประกันการเปลี่ยนสินค้า 1 ปี และ คูปองสุดพิเศษ!  กรอกแบบฟอร์ม , ลงทะเบียนเลย!',
    Privacytip:" การลงทะเบียนเป็นสมาชิกหมายความว่าคุณยอมรับนโยบายความเป็นส่วนตัวและข้อกำหนดการใช้งานของ Simplus",
    tipredtext: 'จำเป็นต้อง.',
    mobiletip: '* กรุณากรอกหมายเลขโทรศัพท์มือถือที่ไม่มีรหัสประเทศ',
    Emailautotip: "คูปองของคุณจะถูกส่งไปยังอีเมลของคุณเร็ว ๆ นี้",
    Nextstep: 'ถัดไป',
    Previous: 'ก่อนหน้า',
    userCenter: 'ศูนย์ผู้ใช้',
    prerequisites: {
      title: 'คุณเคยซื้อผลิตภัณฑ์ Simplus มาก่อนมั้ย?',
      list: [{
        value: 'yes',
        label: 'ใช่แล้ว,  อยากจะแบ่งปันรีวิวและรับคูปองส่วนลด 50% (ใช้ได้ในการสั่งซื้อครั้งต่อไป)',
      }, {
        value: 'no',
        label: 'ไม่, ฉันต้องการเป็นสมาชิกของ Simplus'
      }],
    },
    member: {
      Title: 'โพสต์รีวิวรับเวาเชอร์ 30 บาท!!!',
      onetitle: "แชร์ภาพหน้าจอรีวิวระดับ 5 ดาวของคุณ (โปรดอย่าใส่การ์ดขอบคุณไว้ในภาพนี้)",
      onetip: "อัพโหลดไฟล์",
      twotitle: 'แพลตฟอร์มที่คุณซื้อสินค้า',
      twolist: ['Lazada', 'Shopee', 'TikTok','อื่นๆ'],
      Introductory: "ขอบคุณที่เลือกผลิตภัณฑ์ของ Simplus, โปรดกรอกข้อมูลสินค้าในออเดอร์ของคุณเพื่อรับคูปอง 50% ,สำหรับการสั่งซื้อสินค้า Simplus ในครั้งต่อไปของคุณ:",
      chatID: "LINE ID",
      chatTips: "โปรดกรอกข้อมูล",
      OrderID: " หมายเลขคำสั่งซื้อ",
      OrderTips: "โปรดกรอกข้อมูล",
      Platform: "แพลตฟอร์มที่แสดงความคิดเห็นรีวิว(ตัวเลือกเดียว)",
      EvaluationPlatform: "แพลตฟอร์มที่แสดงความคิดเห็นรีวิว(ตัวเลือกเดียว)",
      EvaluationURl: "(optional)ภาพแคปหน้าจอที่แสดงความคิดเห็น",
      EvaluationURlTips: "โปรดอัพโหลดรูปภาพ",
      EvaluationString: "ลิงก์ความคิดเห็น",
      EvaluationStringTips: "โปรดกรอกข้อมูล",
      Nation: null,
      btntitle: "เสร็จสิ้นการสมัคร",
      FormConclusion: "ยินดีด้วย! คุณลงทะเบียนสำเร็จแล้ว!  รีบมาช้อปและเอนจอยกับสิทธิประโยชน์การรับประกัน 1 ปีได้เลย!",
    },
    sliginform: {
      Title: 'ลงชื่อสมาชิก Simplus ของเรา',
      e_title: 'อีเมล(กรุณาตรวจสอบที่อยู่อีเมลที่ถูกต้อง)',
      p_title: 'เบอร์โทรศัพท์',
      p_title_red: ' *(mobile number)',
      p_title_tip: ' เบอร์โทรศัพท์ไม่ถูกต้อง',
      e_title_tip: ' อีเมลไม่ถูกต้อง',
      n_title: 'ชื่อ',
      d_title: 'วันเกิด',
      g_title: 'เพศ',
      g_list: [{
          value: 'Male',
          label: 'ผู้ชาย',
        },
        {
          value: 'Female',
          label: 'ผู้หญิง',
        },
        {
          value: 'Not_Specified',
          label: 'ไม่ระบุ',
        },
      ],
      Where_title: 'คุณเจอเราได้อย่างไร',
      Where_list: ['Facebook', 'Instagram', 'Line', 'Lazada', 'Shopee', 'TikTok', 'โฆษณาลิฟต์'],
      Where_other: 'other (Please specify)',
      lastmeber: 'การลงทะเบียนเป็นสมาชิกหมายความว่าคุณยอมรับนโยบายความเป็นส่วนตัวและข้อกำหนดการใช้งานของ Simplus',
      lasttext: "การสร้างบัญชีหมายความว่าคุณยอมรับนโยบายความเป็นส่วนตัวและข้อกำหนดการใช้งานของ Simplus",
      downbtntext: 'ก่อนหน้า',
      upbtntexts: 'ก่อนหน้า',
      upbtntext: 'ก่อนหน้า',
      submitext: 'สิ้นสุดการลงทะเบียน',
    }
  }
},
{
  Nation: 'VN',
  index: {
    rtext: 'thành viên mới',
    rbtn: 'Đăng ký',
    stext: 'Đã là thành viên hay chưa.',
    sbtn: 'Đăng nhập.',
    ctext: 'quốc tế',
  },
  user: {
    text: 'Yêu cầu thông tin thành viên',
    ptitle: 'số điện thoại',
    ptext: 'Phone No.',
    or: 'hoặc',
    etitle: 'địa chỉ email',
    etext: 'Email',
    ctext: 'Quốc gia',
    sbtn: 'Đăng nhập',
    rdtext: 'Ngày đăng ký'
  },
  registration: {
    ctext: 'Quốc gia',
    PTip: 'Hãy nhập số điện thoại mà không có mã quốc gia.',
    tiptext: 'Cảm ơn bạn đã lựa chọn Simplus! Trở thành thành viên Simplus và nhận bảo hành thay thế 1 năm và các phiếu mua hàng độc quyền!',
    tipredtext: 'Bắt buộc.',
    Privacytip:"Đăng ký làm thành viên có nghĩa là bạn chấp nhận Chính sách quyền riêng tư và Điều khoản sử dụng của Simplus",
    mobiletip: 'Vui lòng nhập số điện thoại không có mã quốc gia.',
    Emailautotip: "Tấm vé của bạn sẽ sớm được gửi tới email của bạn",
    Nextstep: 'bước tiếp theo',
    Previous: 'Trở về trang trước đó',
    userCenter: 'Trung tâm người dùng',
    prerequisites: {
      title: 'Bạn đã từng mua sản phẩm Simplus chưa?',
      list: [{
        value: 'yes',
        label: 'tôi đã từng mua ạ , tôi muốn chia sẻ đánh giá của mình và nhận phiếu giảm giá 50% (có giá trị cho đơn hàng tiếp theo)',
      }, {
        value: 'no',
        label: 'Chưa, tôi muốn trở thành thành viên simplus'
      }],
    },
    member: {
      Title: 'ĐĂNG PHẦN ĐÁNH GIÁ ĐỂ NHẬN PHIẾU GIẢM GIÁ 10%',
      onetitle: "Chia sẻ ảnh chụp màn hình phần đánh giá 5 sao của bạn ~ (Vui lòng không đăng phiếu tri ân này kèm với ảnh này)",
      onetip: "tải tệp lên",
      twotitle: 'Nền tảng bạn đã mua sắm',
      twolist: ['Lazada', 'Shopee', 'TikTok','Khác'],
      Introductory: "Cảm ơn bạn đã chọn sản phẩm của Simplus, vui lòng điền thông tin danh sách sản phẩm của bạn để nhận phiếu giảm giá 50% cho đơn hàng Simplus tiếp theo :",
      chatID: "ID Facebook của bạn",
      chatTips: "Vui lòng điền",
      OrderID: "Số đơn hàng",
      OrderTips: "Vui lòng điền",
      Platform: "Nền tảng đặt hàng (một lựa chọn)",
      EvaluationPlatform: "Nền tảng bình luận (một lựa chọn) ",
      EvaluationURl: "Ảnh chụp màn hình nhận xét (tùy chọn)",
      EvaluationURlTips: "vui lòng tải lên",
      EvaluationString: "Liên kết bình luận",
      EvaluationStringTips: "Vui lòng điền",
      Nation: null,
      btntitle: "Hoàn thành đơn đăng ký",
      FormConclusion: "Xin chúc mừng! Bạn đã đăng ký thành công! Hãy đến cửa hàng và tận hưởng lợi ích bảo hành 1 năm của bạn!",
    },
    sliginform: {
      Title: 'Đăng ký Thành viên Simplus của chúng tôi',
      e_title: 'Địa chỉ email(Vui lòng điền chính xác địa chỉ Email)',
      p_title: 'Số điện thoại',
      p_title_red: ' *(mobile number)',
      p_title_tip: 'Số điện thoại không hợp lệ',
      e_title_tip: 'Địa chỉ email không hợp lệ',
      n_title: 'Tên',
      d_title: 'Ngày sinh',
      g_title: 'Giới tính',
      g_list: [{
          value: 'Male',
          label: 'Nam',
        },
        {
          value: 'Female',
          label: 'Nữ',
        },
        {
          value: 'Not_Specified',
          label: 'Không muốn nói ra',
        },
      ],
      Where_title: 'Bạn đã tìm thấy chúng tôi qua kênh nào?',
      Where_list: ['Facebook', 'Instagram', 'Line', 'Lazada', 'Shopee', 'TikTok'],
      Where_other: 'Khác (vui lòng ghi rõ)',
      lastmeber: 'Đăng ký làm thành viên có nghĩa là bạn chấp nhận Chính sách quyền riêng tư và Điều khoản sử dụng của Simplus',
      lasttext: "Đăng ký thành viên nghĩa là bạn chấp nhận Chính sách Quyền riêng tư và Điều khoản Sử dụng của Simplus",
      downbtntext: 'bước tiếp theo',
      upbtntexts: 'Bước trước ',
      upbtntext: 'Bước trước',
      submitext: 'Hoàn thành đơn đăng ký',
    }
  }
  },
  {
    Nation: 'ID',
    index: {
      rtext: 'Anggota baru',
      rbtn: 'Daftar',
      stext: 'Sudah menjadi anggota',
      sbtn: 'Masuk',
      ctext: 'Negara',
    },
    user: {
      text: 'isi informasi anggota',
      ptitle: 'Nomor HP',
      ptext: 'No. HP',
      or: 'atau',
      etitle: 'Alamat E-mail',
      etext: 'Email',
      ctext: 'Negara',
      sbtn: 'Masuk',
      rdtext: 'tanggal registrasi'
    },
    registration: {
      ctext: 'Negara',
      PTip: 'Silakan masukkan nomor ponsel tanpa kode negara',
      tiptext: 'Terima kasih anda telah memilih Simplus!Bergabunglah menjadi anggota Simplus, dapatkan garansi penggantian 1 tahun dan voucher eksklusif!  Isi formulir, selesaikan pendaftaran!',
      Privacytip:"Mendaftar sebagai anggota berarti anda menyetujui Kebijakan Privasi dan Ketentuan Penggunaan Simplus",
      tipredtext: 'Harus diisi.',
      mobiletip: '* Silakan masukkan nomor ponsel tanpa kode negara.',
      Emailautotip: "  Kupon Anda akan segera dikirim ke email Anda.",
      Nextstep: 'lanjut',
      Previous: 'Langkah sebelumnya',
      userCenter: 'user Center',
      prerequisites: {
        title: 'Apakah sebelumnya ada pernah membeli produk Simplus?',
        list: [{
          value: 'yes',
          label: 'Iya. Saya ingin membagikan ulasan saya, dan mendapatkan voucher eksklusif potongan 50% (Berlaku pembelian berikutnya)',
        }, {
          value: 'no',
          label: 'Tidak. Saya ingin menjadi anggota Simplus'
        }],
      },
      member: {
        Title: 'POST REVIEW UNTUK MENDAPATKAN DISKON 10% VOUCHER',
        onetitle: "Bagikan screenshot ulasan bintang 5 Anda~ (Jangan taruh kartu ucapan terima kasih di dalam foto ini)",
        onetip: "unggah berkas",
        twotitle: 'Platform tempat Anda membeli',
        twolist: ['Lazada', 'Shopee',  'TikTok','lainnnya'],
        Introductory: "Terima kasih anda telah memilih produk SIMPLUS. Posting pengalaman menggunakan produk SIMPLUS di Media Sosial anda (Facebook/Instagram/TikTok) akan mendapatkan voucher diskon 50%off untuk pemesanan SIMPLUS berikutnya. Isi form, segera ikuti programnya",
        chatID: "Akun Facebook",
        chatTips: "Silahkan isi",
        OrderID: "Nomor Pesanan",
        OrderTips: "Silahkan isi",
        Platform: "Platform pemesanan (pilih satu) ",
        EvaluationPlatform: "Media sosial tempat menggirimkan postingan ",
        EvaluationURl: "Capture postingan (opsional)",
        EvaluationURlTips: "Silahkan unggah",
        EvaluationString: "Link postingan ",
        EvaluationStringTips: "Silahkan isi",
        Nation: null,
        btntitle: "aplikasi lengkap",
        FormConclusion: " Selamat! Anda telah berhasil mendaftar! Ayo berbelanja, nikmati manfaat garansi 1 tahun!",
      },
      sliginform: {
        Title: 'Daftar keanggotaan Simplus kami',
        e_title: 'Alamat E-mail(Mohon pastikan mengisi alamat email dengan benar)',
        p_title: 'Nomor HP',
        p_title_red: '',
        p_title_tip: 'Nomor telepon tidak valid',
        e_title_tip: 'Alamat email tidak valid',
        n_title: 'Nama',
        d_title: 'Tanggal lahir',
        g_title: 'Jenis kelamin ',
        g_list: [{
            value: 'Male',
            label: 'Pria',
          },
          {
            value: 'Female',
            label: 'Wanita',
          },
          {
            value: 'Not_Specified',
            label: 'Rahasia',
          },
        ],
        Where_title: 'Dari mana Anda menemukan kami?',
        Where_list: ['Facebook', 'Instagram', 'Line', 'Lazada',  'TikTok', 'iklan lift', 'Yang lain'],
        Where_other: 'lainnnya (sebutkan)',
        lastmeber: ' Mendaftar sebagai anggota berarti anda menyetujui Kebijakan Privasi dan Ketentuan Penggunaan Simplus',
        lasttext: "Mendaftar sebagai anggota berarti Anda menerima kebijakan privasi dan syarat penggunaan Simplus",
        downbtntext: 'lanjut',
        upbtntexts: 'kembali',
        upbtntext: 'kembali',
        submitext: 'Selesai',
      }
    }
    },
{
  Nation: 'SG',
  index: {
    rtext: 'New Registration',
    rbtn: 'Join Now',
    stext: 'Already as Member',
    sbtn: 'Sgin in',
    ctext: 'Country',
  },
  user: {
    text: 'Query member information',
    ptitle: 'Phone Number',
    ptext: 'Phone No.',
    or: 'or',
    etitle: 'E-mail Address',
    etext: 'Email',
    ctext: 'Country',
    sbtn: 'Sgin in',
    rdtext: 'Registration date'
  },
  registration: {
    ctext: 'Country',
    PTip: 'Please enter mobile number without country code',
    tiptext: 'Thank you for choosing Simplus!  Join Simplus membership to get 1 year replacement warranty and exclusive voucher!  Fill in the form to complete the registration!',
    Privacytip:"To register membership means you accept Simplus' Privacy Policy and Terms of Use",
    tipredtext: 'Mandatory Field.',
    mobiletip: '* Please enter mobile number without country code.',
    Emailautotip: "Your coupon will be sent to your email shortly.",
    Nextstep: 'Next step',
    Previous: 'Previous',
    userCenter: 'user Center',
    prerequisites: {
      title: 'Have you shopped with Simplus before?',
      list: [{
        value: 'yes',
        label: 'Yes, I want to share my reviews and get 50%off voucher for my next order',
      }, {
        value: 'no',
        label: 'No, I want to become a Simplus member.'
      }],
    },
    member: {
      Title: 'POST REVIEW TO GET 10% OFF VOUCHER',
      onetitle: "Share your screenshot of the 5 stars review~ (Please don't put thank you card together within this photo)",
      onetip: "upload files",
      twotitle: 'Platform that you purchased from ',
      twolist: ['Lazada', 'Shopee', 'TikTok','Others'],
      Introductory: "Thank you for shopping with Simplus , please fill in your product review information to receive 50% off voucher on your next Simplus order",
      chatID: "Your Facebook account",
      chatTips: "Please fill in",
      OrderID: "Order ID",
      OrderTips: "Please fill in",
      Platform: " Your order platform",
      EvaluationPlatform: "Social platform where you leave reviews ",
      EvaluationURl: " Screenshot of your review page(optional)",
      EvaluationURlTips: "Please upload",
      EvaluationString: "Link to your review page",
      EvaluationStringTips: "Please fill in",
      Nation: null,
      btntitle: "Complete application",
      FormConclusion: "Congratulations! Your resgistration is successful! Come to shop and enjoy your 1 year warranty!",
    },
    sliginform: {
      Title: 'Sign up our Simplus Membership',
      e_title: 'E-mail Address(Please ensure to fill in the correct Email address)',
      p_title: 'Phone Number',
      p_title_red: ' *(mobile number)',
      p_title_tip: 'Error entering mobile number',
      e_title_tip: 'Email format error',
      n_title: 'Name',
      d_title: 'Date of Birth',
      g_title: 'Gender',
      g_list: [{
          value: 'Male',
          label: 'Male',
        },
        {
          value: 'Female',
          label: 'Female',
        },
        {
          value: 'Not_Specified',
          label: 'Prefer not to say',
        },
      ],
      Where_title: 'How did you know Simplus？',
      Where_list: ['Facebook', 'Instagram', 'Line', 'Lazada', 'Shopee', 'TikTok', 'Elevator advertisement'],
      Where_other: 'other (Please specify)',
      lastmeber: 'Hello, please fill out this form to get exclusive coupons! Thanks for submitting your message! Your special coupon will be sent to your email shortly.',
      lasttext: "To register membership means you accept Simplus' Privacy Policy and Terms of Use",
      downbtntext: 'Next step',
      upbtntexts: 'Previous page',
      upbtntext: 'Previous',
      submitext: 'Complete application',
    }
  }
  },
  {
    Nation: 'PH',
    index: {
      rtext: 'New Registration',
      rbtn: 'Join Now',
      stext: 'Already as Member',
      sbtn: 'Sgin in',
      ctext: 'Country',
    },
    user: {
      text: 'Query member information',
      ptitle: 'Phone Number',
      ptext: 'Phone No.',
      or: 'or',
      etitle: 'E-mail Address',
      etext: 'Email',
      ctext: 'Country',
      sbtn: 'Sgin in',
      rdtext: 'Registration date'
    },
    registration: {
      ctext: 'Country',
      PTip: 'Please enter mobile number without country code',
      tiptext: 'Thank you for choosing Simplus!  Join Simplus membership to get 1 year replacement warranty and exclusive voucher!  Fill in the form to complete the registration!',
      Privacytip:"To register membership means you accept Simplus' Privacy Policy and Terms of Use",
      tipredtext: 'Mandatory Field.',
      mobiletip: '* Please enter mobile number without country code.',
      Emailautotip: "Your coupon will be sent to your email shortly.",
      Nextstep: 'Next step',
      Previous: 'Previous',
      userCenter: 'user Center',
    prerequisites: {
      title: 'Have you shopped with Simplus before?',
      list: [{
        value: 'yes',
        label: 'Yes, I want to share my reviews and get 50%off voucher for my next order',
      }, {
        value: 'no',
        label: 'No, I want to become a Simplus member.'
      }],
    },
      member: {
        Title: 'POST REVIEW TO GET 10% OFF VOUCHER',
        onetitle: "Share your screenshot of the 5 stars review~ (Please don't put thank you card together within this photo)",
        onetip: "upload files",
        twotitle: 'Platform that you purchased from ',
        twolist: ['Lazada', 'Shopee','TikTok','Others'],
        Introductory: "Thank you for shopping with Simplus , please fill in your product review information to receive 50% off voucher on your next Simplus order",
        chatID: "Your Facebook account",
        chatTips: "Please fill in",
        OrderID: "Order ID",
        OrderTips: "Please fill in",
        Platform: " Your order platform",
        EvaluationPlatform: "Social platform where you leave reviews ",
        EvaluationURl: " Screenshot of your review page(optional)",
        EvaluationURlTips: "Please upload",
        EvaluationString: "Link to your review page",
        EvaluationStringTips: "Please fill in",
        Nation: null,
        btntitle: "Complete application",
        FormConclusion: "Congratulations! Your resgistration is successful! Come to shop and enjoy your 1 year warranty!",
      },
      sliginform: {
        Title: 'Sign up our Simplus Membership',
        e_title: 'E-mail Address(Please ensure to fill in the correct Email address)',
        p_title: 'Phone Number',
        p_title_red: ' *(mobile number)',
        p_title_tip: 'Error entering mobile number',
        e_title_tip: 'Email format error',
        n_title: 'Name',
        d_title: 'Date of Birth',
        g_title: 'Gender',
        g_list: [{
            value: 'Male',
            label: 'Male',
          },
          {
            value: 'Female',
            label: 'Female',
          },
          {
            value: 'Not_Specified',
            label: 'Prefer not to say',
          },
        ],
        Where_title: 'How did you know Simplus？',
        Where_list: ['Facebook', 'Instagram', 'Line', 'Lazada', 'Shopee', 'TikTok', 'Elevator advertisement'],
        Where_other: 'other (Please specify)',
        lastmeber: 'Hello, please fill out this form to get exclusive coupons! Thanks for submitting your message! Your special coupon will be sent to your email shortly.',
        lasttext: "To register membership means you accept Simplus' Privacy Policy and Terms of Use",
        downbtntext: 'Next step',
        upbtntexts: 'Previous page',
        upbtntext: 'Previous',
        submitext: 'Complete application',
      }
    }
  },
  {
    Nation: 'MY',
    index: {
      rtext: 'New Registration',
      rbtn: 'Join Now',
      stext: 'Already as Member',
      sbtn: 'Sgin in',
      ctext: 'Country',
    },
    user: {
      text: 'Query member information',
      ptitle: 'Phone Number',
      ptext: 'Phone No.',
      or: 'or',
      etitle: 'E-mail Address',
      etext: 'Email',
      ctext: 'Country',
      sbtn: 'Sgin in',
      rdtext: 'Registration date'
    },
    registration: {
      ctext: 'Country',
      PTip: 'Please enter mobile number without country code',
      tiptext: 'Thank you for choosing Simplus!  Join Simplus membership to get 1 year replacement warranty and exclusive voucher!  Fill in the form to complete the registration!',
      Privacytip:"To register membership means you accept Simplus' Privacy Policy and Terms of Use",
      tipredtext: 'Mandatory Field.',
      mobiletip: '* Please enter mobile number without country code.',
      Emailautotip: "Your coupon will be sent to your email shortly.",
      Nextstep: 'Next step',
      Previous: 'Previous',
      userCenter: 'user Center',
    prerequisites: {
      title: 'Have you shopped with Simplus before?',
      list: [{
        value: 'yes',
        label: 'Yes, I want to share my reviews and get 50%off voucher for my next order',
      }, {
        value: 'no',
        label: 'No, I want to become a Simplus member.'
      }],
    },
      member: {
        Title: 'POST REVIEW TO GET 10% OFF VOUCHER',
        onetitle: "Share your screenshot of the 5 stars review~ (Please don't put thank you card together within this photo)",
        onetip: "upload files",
        twotitle: 'Platform that you purchased from ',
        twolist: ['Lazada', 'Shopee', 'TikTok','Others'],
        Introductory: "Thank you for shopping with Simplus , please fill in your product review information to receive 50% off voucher on your next Simplus order",
        chatID: "Your Facebook account",
        chatTips: "Please fill in",
        OrderID: "Order ID",
        OrderTips: "Please fill in",
        Platform: " Your order platform",
        EvaluationPlatform: "Social platform where you leave reviews ",
        EvaluationURl: " Screenshot of your review page(optional)",
        EvaluationURlTips: "Please upload",
        EvaluationString: "Link to your review page",
        EvaluationStringTips: "Please fill in",
        Nation: null,
        btntitle: "Complete application",
        FormConclusion: "Congratulations! Your resgistration is successful! Come to shop and enjoy your 1 year warranty!",
      },
      sliginform: {
        Title: 'Sign up our Simplus Membership',
        e_title: 'E-mail Address(Please ensure to fill in the correct Email address)',
        p_title: 'Phone Number',
        p_title_red: ' *(mobile number)',
        p_title_tip: 'Error entering mobile number',
        e_title_tip: 'Email format error',
        n_title: 'Name',
        d_title: 'Date of Birth',
        g_title: 'Gender',
        g_list: [{
            value: 'Male',
            label: 'Male',
          },
          {
            value: 'Female',
            label: 'Female',
          },
          {
            value: 'Not_Specified',
            label: 'Prefer not to say',
          },
        ],
        Where_title: 'How did you know Simplus？',
        Where_list: ['Facebook', 'Instagram', 'Line', 'Lazada', 'Shopee', 'TikTok', 'Elevator advertisement',],
        Where_other: 'other (Please specify)',
        lastmeber: 'Hello, please fill out this form to get exclusive coupons! Thanks for submitting your message! Your special coupon will be sent to your email shortly.',
        lasttext: "To register membership means you accept Simplus' Privacy Policy and Terms of Use",
        downbtntext: 'Next step',
        upbtntexts: 'Previous page',
        upbtntext: 'Previous',
        submitext: 'Complete application',
      }
    }
    }
]
