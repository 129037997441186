<template>
	<div class="select-country">
		<!-- 下拉框  -->
		<div class="country-label" :style="{zIndex:labelIndex+' !important'}" @click="handShow">
			<span class="label-text" v-if="current==-1">—</span>
			<img class="label-image" v-else :src="countryList[current].img">
			<img class="label-down" src="@/assets/images/icons/down.png">
		</div>
		<!-- 选择国家 -->
		<van-popup :close-on-click-overlay="false" :round="true" :overlay="true"
			:overlay-style="{background:'rgba(0,0,0,0.3)'}" v-model="showPop">
			<div class="country-view">
				<div class="country-item" v-for="(item,index) in countryList" :key="index"
					@click="nationChange(item.key)">
					<img class="item-image" :src="item.img">
					<span class="item-text">{{item.name}}</span>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import {
		Popup
	} from 'vant';
	import {
		langData
	} from '../../util/lang_config';
	export default {
		props: {
			isShow: {
				type: Boolean,
				default: true,
			},
			labelIndex: {
				type: String,
				default: "9999",
			},
			text: {
				type: String,
				default: "",
			}
		},
		components: {
			[Popup.name]: Popup,
		},
		watch: {
			isShow(val) {
				this.showPop = val;
				if (localStorage.getItem('NationName') && !val) {
					this.Nation = localStorage.getItem('NationName');
					this.langInfo = this.setLang();
				}
				this.Nation = this.text;
			}
		},
		computed: {
			current() {
				return this.countryList.findIndex(item => item.key === this.Nation);
			}
		},
		data() {
			return {
				showPop: true,
				Nation: '',
				countryList: [{
					name: 'ประเทศไทย',
					key: 'TH',
					img: require('@/assets/images/icons/th.png')
				}, {
					name: 'Việt Nam',
					key: 'VN',
					img: require('@/assets/images/icons/vn.png')
				}, {
					name: 'Singapore',
					key: 'SG',
					img: require('@/assets/images/icons/sg.png')
				}, {
					name: 'Malaysia',
					key: 'MY',
					img: require('@/assets/images/icons/my.png')
				}, {
					name: 'Indonesia',
					key: 'ID',
					img: require('@/assets/images/icons/id.png')
				}, {
					name: 'Philippines',
					key: 'PH',
					img: require('@/assets/images/icons/ph.png')
				}]
			};
		},
		mounted() {
			// if (localStorage.getItem('NationName')) {
			// 	this.showPop = false;
			// 	this.Nation = localStorage.getItem('NationName');
			// 	this.$emit('change', this.Nation);
			// 	this.langInfo = this.setLang();
			// } else {
			// 	this.showPop = true;
			// }
		},
		methods: {
			handShow() {
				this.showPop = !this.showPop;
				this.$emit('change', '');
			},
			nationChange(i) {
				this.Nation = i;
				this.langInfo = this.setLang();
				this.showPop = false;
				localStorage.setItem('NationName', i);
				this.$emit('change', i);
			},
			setLang() {
				let Info = langData.filter((res) => res.Nation == this.Nation)[0];
				return Info == undefined ? langData[0] : Info;
			}
		},
	};
</script>

<style lang="less" scoped>
	.select-country {

		.country-label {
			position: absolute;
			top: 24px;
			right: 24px;
			background: #ffffff;
			width: 48px;
			height: 26px;
			display: flex;
			align-items: center;
			justify-content: space-around;
			z-index: 2019 !important;

			.label-image {
				width: 22px;
				height: 22px;
			}

			.label-text {
				display: inline-block;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #0e0e0e;
			}

			.label-down {
				width: 12px;
				height: 12px;
			}
		}

		.country-view {
			flex: 1;
			padding: 24px;
			width: 60vw;
			background: #ffffff;
			border-radius: 24px;

			.country-item {
				background: #f8f8f8;
				height: 32px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 12px;
				border-radius: 8px;

				.item-image {
					width: 22px;
					height: 22px;
				}

				.item-text {
					width: 66px;
					display: inline-block;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #0e0e0e;
					padding-left: 8px;
				}
			}

			.country-item:last-child {
				margin-bottom: 0px;
			}
		}
	}
</style>